var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "layout": "vertical",
      "finish-button-text": "Finalizar",
      "back-button-text": "Anterior",
      "next-button-text": "Próximo"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Dados da Professor"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Dados do Professor")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" Insira os dados do Professor ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome do(a) Professor(a)",
      "label-for": "v-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-username",
      "placeholder": "Insira o nome aqui"
    },
    model: {
      value: _vm.form.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fullName", $$v);
      },
      expression: "form.fullName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "v-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-email",
      "type": "email",
      "placeholder": "exemplo@email.com"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Senha",
      "label-for": "v-password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-password",
      "placeholder": "Insira o a senha aqui"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Disciplina",
      "label-for": "v-discipline"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-discipline",
      "placeholder": "Insira o disciplina aqui"
    },
    model: {
      value: _vm.form.discipline,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discipline", $$v);
      },
      expression: "form.discipline"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "xl": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "v-schoolId",
      "label": "Escola"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "schoolId",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.schoolOptions,
      "value": "id",
      "label": "name",
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.form.schoolId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "schoolId", $$v);
      },
      expression: "form.schoolId"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }