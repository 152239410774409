<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Finalizar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account datails tab -->
      <tab-content title="Dados da Professor">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Dados do Professor</h5>
            <small class="text-muted"> Insira os dados do Professor </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome do(a) Professor(a)"
              label-for="v-username"
            >
              <b-form-input
                id="v-username"
                v-model="form.fullName"
                placeholder="Insira o nome aqui"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="E-mail" label-for="v-email">
              <b-form-input
                id="v-email"
                v-model="form.email"
                type="email"
                placeholder="exemplo@email.com"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Senha" label-for="v-password">
              <b-form-input
                id="v-password"
                v-model="form.password"
                placeholder="Insira o a senha aqui"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Disciplina" label-for="v-discipline">
              <b-form-input
                id="v-discipline"
                v-model="form.discipline"
                placeholder="Insira o disciplina aqui"
              />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12" xl="6">
            <b-form-group label-for="v-schoolId" label="Escola">
              <v-select
                id="schoolId"
                v-model="form.schoolId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="schoolOptions"
                value="id"
                label="name"
                item-text="name"
                item-value="id"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import usersService from '@/services/usersService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import schoolService from '@/services/schoolService'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: ['id'],
  data() {
    return {
      selectedContry: 'select_value',
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      schoolOptions: [{ value: null, text: 'Selecione uma escola' }],

      form: {
        id: null,
        fullName: null,
        username: null,
        email: null,
        password: null,
        discipline: null,
        schoolId: null,
        role: 3, // professor
      },
    }
  },
  mounted() {
    this.getSchoolOptions()
    if (this.$route.params.id) {
      this.getProfessorInfo()
    }
  },
  methods: {
    async formSubmitted() {
      showSpinner()
      if (this.$route.params.id) {
        this.fetchedUsers = await usersService
          .update({ ...this.form, schoolId: this.form.schoolId?.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/ProfessorLista')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Erro ao atualizar professor. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.fetchedUsers = await usersService
          .create({ ...this.form, schoolId: this.form.schoolId?.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/ProfessorLista')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Erro ao criar professor. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      }
    },
    async getProfessorInfo() {
      try {
        const professor = await usersService.getById(this.$route.params.id)
        console.log(professor, "professor")
        professor.password = ''
        this.form = professor
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Professor!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    async getSchoolOptions() {
      try {
        const filtro = {
          name: '',
          page: 1,
          itemsPerPage: 99,
        }
        const schoolOptions = await schoolService.getAll(filtro)
        this.schoolOptions = schoolOptions.content
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar escolas!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
